const guideTasks = {
    en: [
        {
            desc: "The solar clock on the wall of the Ratusz isn't usual. Find the abnormal number there and write it below.",
            answer: 4,
            hint: "Check each number. One doesn’t look like normal Roman numerals. Write that number in arabic numerals in the answer."
        },
        {
            desc: "How many people with the swords are on the Artus House?",
            answer: 5,
            hint: "It’s easy, few people are holding swords in their hands (on the picture). How many such people with the swords are on the Artus House?"
        },
        {
            desc: "Find another Neptune nearby and year below it. The year is the answer.",
            answer: 1953, //(to check once again)
            hint: "When you saying near Neptune Fountain, look carefully around and find another figure of Neptune from there. Below it there is written a year in Roman numerals. If you don’t know how to transform Roman numerals into Arabic numerals, check our Cipher manual in the top left corner of the task screen."
        },
        {
            desc: "50",
            answer: 10,
            hint: "Look at Fahrenheit's thermometer and think..."
        },
        {
            desc: "How many full body statues on the Green Gate are visible from the Green Bridge?",
            answer: 10,
            hint: "Just stand on the Green Bridge and look at the Green Gate. Calculate the full body statues on it."
        },
        {
            desc: "What is the approximate diameter in meters of the wheel in the Crane?",
            answer: 6,
            hint: "You have some scope measurements in the Gate entrance, based on that, think what was the diameter of the wheel."
        },
        {
            desc: "How many women are depicted on the terraces along the Mariacka street.",
            answer: 10,
            hint: "Count only women on the terraces (balconies)."
        },
        {
            desc: "Go around the St. Mary's Basilica and find 3 boar heads depicted on the wall next to Basilica. There are numbers under the heads. The last number is the answer.",
            answer: 8,
            hint: "Boar is a wild pig. Three heads of the boars are depicted on the wall next to Basilica. Go around it and you will find them."
        },
        {
            desc: "Go inside St. Mary's Basilica. There is a famous astronomical clock. How many humans are pictured on the clock circle? ",
            answer: 6,
            hint: "There is a big astronomical clock in the Basilica. Find it and calculate the people on the clock. Don’t take into account the human figures above the clock."
        },
        {
            desc: "Świętego Ducha (Sancti Spiritus) 45/47 is the building where the famous philosopher Arthur Schopenhauer was born. Actually on the same street was born his mother. Move in the direction towards the river and find the house with the turtle on the top. The number of the house (the first one) is the answer.",
            answer: 111,
            hint: "Go along the street and look on the top of the buildings to find the turtle on one of them."
        },
        {
            desc: "Find the woman with the spear. This is Minerwa, the Roman goddess of wisdom. Find the year she is pointing with the spear.",
            answer: 1768,
            hint: "The spear is a pole weapon with a sharp point in the hand of Minerwa."
        },
        {
            desc: "Find the year when the Gate was finished.",
            answer: 1588,
            hint: "It’s the earliest year shown on the Gate."
        },
        {
            desc: "There is the headless sculpture withible from the inside and outside of the Prison Tower. When you find it, look at the direction where the head of the headless guy suppose to look and you will see the number.",
            answer: 2004,
            hint: "You can see the sculpture even from outside of the wall."
        },
        {
            desc: "To know what is the amulet solve the Templars Cipher below.",
            answer: "Sun",
            hint: "To decode the Templar Cipher, use the Cipher Manual at the top left corner of the task screen."
        },
    ],
}

export default guideTasks;
