import React from "react";
import { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { ActionTypes } from '../../constants/action-types'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import cipherTask from './maps/cipherTask.png';
import guideTasks from './guide';

function Task({ updatePoints, goToPage, nextPage, nextTask, lang, numberTask, points }) {

  const [answer, setAnswer] = useState("");
  const [error, setError] = useState(false);
  const [typeAnswer, setTypeAnswer] = useState('');

  const task = numberTask + 1;

  const goTo = (point, page, next) => {
    updatePoints(points + point);
    if (next) nextTask();
    if (guideTasks[lang].length === task && next) goToPage('finish');
    else goToPage(page);
  }

  const handleSubmit = event => {
    if (event.key === 'Enter' || event.key === undefined){
      let rightAnswer, newAnswer;
      if (isNaN(guideTasks[lang][numberTask].answer)) {
        rightAnswer = guideTasks[lang][numberTask].answer.replace(/(^\s*)|(\s*)$/g, '').toLowerCase();
        newAnswer = answer.replace(/(^\s*)|(\s*)$/g, '').toLowerCase();
      } else {
        rightAnswer = guideTasks[lang][numberTask].answer;
        newAnswer = parseInt(answer);
      }
      if (newAnswer === rightAnswer) goTo(10, nextPage, true);
      else goTo(-1, 'taskWrong');

      event.preventDefault();
    }
  }

  const scrollBottom = (e, active = false) => {
    if (active && /Mobi/.test(navigator.userAgent)) {
      setTimeout(() => window.scrollBy({ top: document.body.scrollHeight, behavior: "smooth"}), 300);
    }
  }

  const handleChange = (e) => {
    const type = isNaN(guideTasks[lang][numberTask].answer);
    setTypeAnswer((type) ? 'the word in letters' : 'number');
    const regex = /\d+/g;
    if (typeAnswer === 'the word in letters'){
      if (e.target.value.match(regex) === null) {
        if (error) setError(false);
        setAnswer(e.target.value);
      } else setError(true);
    } else {
      if (type === isNaN(e.target.value) || e.target.value === '') {
        if (error) setError(false);
        setAnswer(e.target.value);
      } else setError(true);
    }
  }

  const styled = {
    borderRadius: '15px',
    border: '1px solid',
    borderColor: '#80241f'
  }

  return (
    <div className="block-container">
      <Button variant="outlined" className="top-button" onClick={() => goToPage('cipher')}>
        Cipher manual
      </Button>
      <div className="space"></div><Button variant="outlined" className="top-button" onClick={() => goTo(-1, 'hint')}>
        Hint
      </Button>

      <div className="title">Task {task}</div>
      <div className="text">{
        (task === 10) ?
            <img src={require(`../tasks/maps/${task}-1.png`)} alt="" style={{...styled }} />
            : null
        }
        {guideTasks[lang][numberTask].desc} <br />
        {(task === 14) ? <div style={{ textAlign: 'center'}}><img src={cipherTask} alt="cipher this Task" /></div> : null} <br />
      </div>
      <div className="text-s centered">Write the answer below and press next</div><br />
      <form>
        <TextField
          autoComplete='off'
          error={error}
          helperText={(error) ? `Please enter ${typeAnswer}` : null}
          id="outlined-error"
          value={answer}
          onChange={e => handleChange(e)}
          placeholder="Your answer"
          FormHelperTextProps={{ classes: { root: 'helperText' } }}
          margin="normal"
          variant="outlined"
          onKeyPress={(e) => handleSubmit(e)}
          onFocus={(e) => scrollBottom(e, true)}
        />
        <br />
        <Button variant="outlined" onClick={handleSubmit}>
          Next
        </Button>
      </form>
    </div>
  );
}


const mapStateToProps = state => {
  return {
    numberTask: state.numberTask,
    lang: state.language,
    points: state.points,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updatePoints: (point) => {
      dispatch({
        type: ActionTypes.UPDATE_POINTS,
        points: point
      })
    },
    nextTask: () => dispatch({ type: ActionTypes.NEXT_TASK }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Task);

