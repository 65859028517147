import React from "react";
import Button from '@material-ui/core/Button';

function TaskWrong({ goToPage, nextPage }) {
    return (
        <div className="block-container">
            <div className="title">Wrong</div>
            <div className="text">You lose 1 point. Go back to the task pressing Back and try again.</div>
            <Button variant="outlined" onClick={() => goToPage(nextPage)}>
                Back
            </Button>
        </div>
    );
}

export default TaskWrong;