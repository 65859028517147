import React from "react";
import { connect } from 'react-redux';
import { ActionTypes } from '../constants/action-types'
import Button from '@material-ui/core/Button';

function FirstDest({ goToPage, nextPage, startQuest }) {

  const goTo = () => {
    startQuest(Date.now());
    goToPage(nextPage);
  }

  return (
    <div className="block-container">
      <div className="title">Go to the First destination</div>
      <div className="ratusz-image"></div>
      <div className="text">Your First destination is Gdansk City Hall (Ratusz). If you already there or know how to get there press Next. If you don't know where is it located, press the button below and it will open Google Maps with navigation to get there.
        </div>

      <Button href="https://goo.gl/maps/TEUibaNzpeC5uK1d7" target="_blank" rel="noopener noreferrer" variant="outlined">
        Find it on Google Maps
      </Button><br />
      <Button variant="outlined" onClick={goTo}>
        Next
      </Button>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    startQuest: (time) => dispatch({ type: ActionTypes.START_QUEST, time }),
  }
}

export default connect(null, mapDispatchToProps)(FirstDest);

