import React from "react";
import { connect } from 'react-redux';
import { ActionTypes } from '../constants/action-types';
import moment from 'moment';
import Button from '@material-ui/core/Button';

function Finish({ goToPage, nextPage, start, timeQuest }) {

  const goTo = () => {
    const diff = moment(Date.now() * 1000).unix() - moment(start * 1000).unix();
    const time = moment.utc(diff).format("HH:mm:ss");
    timeQuest(time);
    goToPage(nextPage);
  }

  return (
    <div className="block-container">
      <div className="title">You are there!</div>
      <div className="text lefted">
        Stand from the western side of the Gate. Now look up. Do you see the amulet?
        That's it. Now you found it and you are the keeper.
        Guard it and pass its secret to your successor, when the time will come.
      </div>
      <Button variant="outlined" onClick={goTo}>
        Next
      </Button>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    start: state.startQuest,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    timeQuest: (time) => dispatch({ type: ActionTypes.TIME_QUEST, time }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Finish);