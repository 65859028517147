import React from 'react';
import './App.css';
import Switcher from "./component/switcher";
import Container from '@material-ui/core/Container';
import Div100vh from 'react-div-100vh';
import { withStyles } from '@material-ui/core/styles';

const GlobalCss = withStyles({
  '@global': {

    '.MuiButton-root': {
      margin: '8px',
  	  color: '#80241f',
    },
    '.top-button': {
      display: 'inline-block',
      color: '#80241f',
      width: '150px',
      marginBottom: '50px',
      '@media (max-device-width: 600px)': {
        width: 'auto'
      }
    },
    '.helperText': {
      color: 'blue'
    },
  },
})(() => null);

function App() {
  return (
  	<div className="App">
  	<GlobalCss />
      <Div100vh>
        <Container maxWidth="md" className="container wrapper">
          <Switcher />
        </Container>
      </Div100vh>
    </div>
  );
}

export default App;
