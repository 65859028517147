import React from "react";
import Button from '@material-ui/core/Button';

function How({ goToPage, nextPage }) {
  return (
    <div className="block-container">
      <div className="title">How it works</div>
      <div className="text lefted">Start:<br />
        The game will start in a specified place in the old town.<br />
        You will get the name of the place and how to get there in the next steps.<br /><br />
        Duration:<br />
        The game will normally last from 1.5 to 2 hours, depending on how fast you will solve the tasks.
          </div>
      <Button variant="outlined" onClick={() => goToPage(nextPage)}>
        Next
      </Button>
    </div>
  );
}

export default How;