import React from "react";
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import guide from '../place/guide';

function TaskSuccess({ goToPage, nextPage, numberTask, lang }) {
  const styled = {
    borderRadius: '15px',
    border: '1px solid',
    borderColor: '#80241f'
  }
  
  return (
    <div className="block-container">
      <div className="title">Task {numberTask} correct!</div>
      <div className="text">You earn 10 points. Now move to your next destination.
      It's {guide[lang][numberTask].title}. You can see the map with the route below or you can search it on Google Maps typing
      '{numberTask != 9 ? guide[lang][numberTask].title : "Świętego Ducha 45/47"}'. When you will be next to it, press "Next" to read about this place and start the next task.
      </div>
      <div className="image">
        {
          (numberTask <= 13) ? 
            <img src={require(`./maps/${numberTask}.png`)} alt={guide[lang][numberTask].title} style={{...styled}} /> 
          : null
        }
        {
          (![6,9].includes(numberTask)) ?
            <img src={require(`../place/places/Zgrada${numberTask + 1}.png`)} alt={guide[lang][numberTask - 1].title} />
            : null
          
        }
      </div>
      <Button variant="outlined" onClick={() => goToPage(nextPage)}>
        Next
      </Button>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    numberTask: state.numberTask,
    lang: state.language
  }
}

export default connect(mapStateToProps, null)(TaskSuccess);