import { ActionTypes } from '../constants/action-types'


function updateObject(oldObject, newValues) {
  return Object.assign({}, oldObject, newValues)
}

const initialState = {
  language: false,
  page: false,
  numberTask: 0,
  points: 0,
  startQuest: false,
  timeQuest: false,
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.INCREASE_ONE: {
      const newNumber = state.slideNumber + 1
      // console.log(state.slideNumber)
      // console.log(newNumber)
      return updateObject(state, {slideNumber : newNumber})
    }
    case ActionTypes.INCREASE_BY: {
      const newNumber = state.slideNumber + action.number
      return updateObject(state, {slideNumber : newNumber})
    }
    case ActionTypes.DECREASE_ONE: {
      const newNumber = state.slideNumber - 1
      // console.log(state.slideNumber)
      // console.log(newNumber)
      return updateObject(state, {slideNumber : newNumber})
    }
    case ActionTypes.DECREASE_BY: {
      const newNumber = state.slideNumber - action.number
      return updateObject(state, {slideNumber : newNumber})
    }
    case ActionTypes.GOTO_BACK: {
      const newNumber = action.number
      const newGoBackNumber = action.goBackNumber
      return updateObject(state, {slideNumber : newNumber,
                                  goBackNumber : newGoBackNumber})
    }
    case ActionTypes.SET_SLIDE: {
      const newNumber = action.number
      return updateObject(state, {slideNumber : newNumber})
    }
    case ActionTypes.NEXT_TASK: {
      return {
        ...state,
        numberTask: ++state.numberTask
      }
    }
    case ActionTypes.SET_LANGUAGE: {
      return {
        ...state, 
        language: action.language
      }
    }
    case ActionTypes.GO_TO_PAGE: {
      return {
        ...state,
        page: action.page
      }
    }
    case ActionTypes.UPDATE_POINTS: {
      return {
        ...state,
        points: action.points
      }
    }
    case ActionTypes.RESTART: {
      return {
        ...initialState
      }
    }
    case ActionTypes.START_QUEST: {
      return {
        ...state,
        startQuest: action.time
      }
    }
    case ActionTypes.TIME_QUEST: {
      return {
        ...state,
        timeQuest: action.time
      }
    }
    default:
      return state
  }
};

export default rootReducer;
