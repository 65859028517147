import React from "react";
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';

function Results({ goToPage, nextPage, lang, points, time }) {
  return (
    <div className="block-container">
      <div className="title">Success!</div>
      <div className="text centered">You have completed the whole quest.<br /><br />
        Your time is:<br />
        {time}<br /><br />
        Your score is:<br />
        {points} points
        </div>
      <Button variant="outlined" onClick={() => goToPage(nextPage)}>
        Next
      </Button>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    lang: state.language,
    points: state.points,
    time: state.timeQuest
  }
}

export default connect(mapStateToProps, null) (Results);