import React from "react";
import { useState } from 'react'
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function Results({ goToPage, nextPage }) {

  const [stars, setStars] = useState(5);
  const [list, setList] = useState('');
  const [message, setMessage] = useState('');
  
  const send = () => {
    sendMessage();
    goToPage(nextPage)
  }

  const sendMessage = () => {
    const url = window.location.host + '/send';
    fetch(document.location.protocol + '//' + url, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        message: `stars: ${stars}, difficulty: ${list || 'good'}, message: "${message}"`
      })
    })
  }

  return (
    <div className="block-container">
      <div className="title">Help us getting better</div>
      <div className="text lefted">1) How would you rate this quest:
          <Box component="fieldset" mb={3} borderColor="transparent">
          <Rating
            name="simple-controlled"
            value={stars}
            onChange={(event, newValue) => {
              setStars(newValue);
            }}
            size="large"
          />
        </Box>
        2) How was the difficulty:<br />
        <RadioGroup aria-label="difficulty" name="difficulty" value={list} onChange={e => setList(e.target.value)}>
          <FormControlLabel value="easy" control={<Radio color="primary" />} label="Too easy" />
          <FormControlLabel value="good" control={<Radio color="primary" />} label="Good as it is" />
          <FormControlLabel value="diffucult" control={<Radio color="primary" />} label="Too diffucult" />
        </RadioGroup> <br />
        3) What will you advice us to change or add:
        </div>
      <form>
        <TextField
          id="outlined-error"
          value={message}
          onChange={e => setMessage(e.target.value)}
          placeholder="Your feedback"
          FormHelperTextProps={{ classes: { root: 'helperText' } }}
          margin="normal"
          variant="outlined"
        />
        <br />
        <Button variant="outlined" onClick={send}>
          Next
        </Button>
      </form>
    </div>
  );
}

export default connect(null, null) (Results);
