import React from "react";
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import cipher from './maps/cipher.png';

function Cipher({ goToPage, nextPage, task }) {
  const styled = { wordSpacing: '10px'};
  return (
    <div className="block-container">
      <div className="title">Cipher manual</div><br />
      <div className="text" >
        <div>
          <ins>Roman numerals</ins> <br /> <br />
          <div style={styled}>
            I–1	X–10 C–100 <br /><br />
            M-1000 V–5	L–50 <br /><br />
            D–500	<br />
          </div>
          <br />
          How it works: <br /><br />
          <div style={styled}>
            IV–4 	XL–40	CM–900<br /><br />
            VI–6 	LX–60	MDC–1600<br />
          </div>
        </div>
        <img src={cipher} alt="cipher" />
      </div>
      <Button variant="outlined" onClick={() => goToPage(nextPage)}>
        Back to the task
      </Button>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    task: state.numberTask
  }
}

export default connect(mapStateToProps, null)(Cipher);