import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import rootReducer from './reducers'
import 'typeface-roboto';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import throttle from 'lodash.throttle';
import { loadState, saveState } from './localStorage';

const persistedState = loadState();
const store = createStore(
  rootReducer,
  persistedState
);
store.subscribe(throttle(() => {
  saveState({
    slideNumber: store.getState().slideNumber,
    language: store.getState().language,
    page: store.getState().page,
    numberTask: store.getState().numberTask,
    points: store.getState().points,
    startQuest: store.getState().startQuest,
  });
}, 1000));

ReactDOM.render(
	<Provider store={store}>
    <App />
  </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
