import React from "react";
import Button from '@material-ui/core/Button';

function Intro({ goToPage, nextPage }) {
  return (
    <div className="block-container">
      <div className="title">What is Travel-mapping</div>
      <div className="text">Travel-mapping is a new way to discover a city. Play quest game, visit the most interesting places of the city and learn their stories.</div>
      <Button variant="outlined" onClick={() => goToPage(nextPage)}>
        Next
      </Button>
    </div>
  );
}

export default Intro;