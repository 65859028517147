import React from "react";
import { connect } from 'react-redux';
import { ActionTypes } from '../constants/action-types'
import Button from '@material-ui/core/Button';

function Language({ setLanguage, goToPage, nextPage }) {

  const listLang = [
    { name: 'Start', value: 'en' },
    /*{ name: 'Pol', value: 'pl' },*/
  ]

  const choice = (lang) => {
    setLanguage(lang);
    goToPage(nextPage);
  }

  return (
    <div>
      <div className="title">Travel-mapping</div>
      {/*<div className="text centered">Select your language</div>*/}
      {
        listLang.map((lang, index) => {
          return(
            <React.Fragment key={index}>
              <Button key={index} variant="outlined" onClick={() => choice(lang.value)}>
                {lang.name}
              </Button>
              <br />
            </React.Fragment>
          )
        })
      }
    </div>
  );	
}

const mapDispatchToProps = dispatch => {
  return {
    setLanguage: (lang) => dispatch({ type: ActionTypes.SET_LANGUAGE, language: lang})
  }
}

export default connect(null, mapDispatchToProps)(Language);