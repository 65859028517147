
export const ActionTypes = {
  GOTO_BACK: 'GOTO_BACK',
  SET_LANGUAGE: 'SET_LANGUAGE',
  GO_TO_PAGE: 'GO_TO_PAGE',
  NEXT_TASK: 'NEXT_TASK',
  UPDATE_POINTS: 'UPDATE_POINTS',
  RESTART: 'RESTART',
  START_QUEST: 'START_QUEST',
  TIME_QUEST: 'TIME_QUEST',
}

