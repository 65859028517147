import React from "react";
import Button from '@material-ui/core/Button';

function Thanks({ goToPage }) {
  const share = (e) => {
    e.preventDefault();
  }

  const paypalOptions = {
    clientId: '12345',
    intent: 'capture'
  }

  const buttonStyles = {
    layout: 'vertical',
    shape: 'rect',
  }

  return (
    <div className="block-container">
      <div className="title">Thanks for playing!</div>
      <div className="text lefted">
        Please rate us on <a href="https://www.tripadvisor.com/Attraction_Review-g274725-d17740979-Reviews-City_Game_Tour-Gdansk_Pomerania_Province_Northern_Poland.html" target="_blank" rel="noopener noreferrer">TripAdvisor</a> and don't forget to leave a review on our <a href="https://www.facebook.com/login/?next=https://www.facebook.com/pg/tramapgda/reviews/" target="_blank">Facebook page</a> {/*<div className="fb-share-button" data-href="https://citygametour.com" data-layout="button_count"></div>*/}
        <br />
        <br />
        Looking forward having you back with us! 🙂
      </div>

      <br />
      
      <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
        <input type="hidden" name="cmd" value="_donations" />
        <input type="hidden" name="business" value="iz@igorzharii.com" />
        <input type="hidden" name="currency_code" value="PLN" />
        <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
        <img alt="" border="0" src="https://www.paypal.com/en_PL/i/scr/pixel.gif" width="1" height="1" />
      </form>

      <br />
      <br />

      <Button variant="outlined" onClick={() => goToPage('end')}>
        Play again
      </Button>
    </div>
  );
}

export default Thanks;
