import React from "react";
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import guideTasks from './guide';

function Hint({ goToPage, nextPage, lang, numberTask }) {
  const task = numberTask + 1;
  return (
    <div className="block-container">
      <div className="title">Hint for task {task}</div><br />
      <div className="text">{guideTasks[lang][numberTask].hint}<br /><br />
        Using the hint you lost 1 point.
        </div>
      <Button variant="outlined" onClick={() => goToPage(nextPage)}>
        Go back to task {task}
      </Button>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    numberTask: state.numberTask,
    lang: state.language,
  }
}

export default connect(mapStateToProps, null)(Hint);