import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const StyledExpansionPanel = withStyles({
  root: {
    '&$expanded': {
      margin: '0px auto 20px auto'
    },

    '&::before': {
      height: '0'
    },

    background: 'none',
    border: 'none',
    color: '#80241f',
    boxShadow: 'none'
  },
  expanded: {},
})(ExpansionPanel);

export const StyledExpandMoreIcon = withStyles({
  root: {
    color: '#80241f'
  }
})(ExpandMoreIcon);
