import React from "react";
import { connect } from 'react-redux';
import { ActionTypes } from '../constants/action-types';

import Language from "./language";
import Intro from "./intro";
import How from "./how";
import Legend from "./legend";
import FirstDest from "./firstDest";
import Place from "./place/place";
import Task from "./tasks/task";
import Hint from "./tasks/hint";
import Cipher from "./tasks/cipher";
import TaskSuccess from "./tasks/taskSuccess";
import TaskWrong from "./tasks/taskWrong";
import Finish from "./finish";
import Results from "./results";
import Feedback from "./feedback";
import Thanks from "./thanks";

class Switcher extends React.Component {

  componentDidUpdate = (prevProps) => {
    if (prevProps.page !== this.props.page && this.props.page === 'end') this.props.restart();
    if (prevProps.page !== this.props.page || prevProps.numberTask !== this.props.numberTask){
      window.scrollTo(0, 0);
    }
  }
  
  renderPage = () => {
    const { page, language, goToPage} = this.props;
    const data = { lang: language, goToPage: goToPage};

    switch (page) {
      case 'intro':
        data.nextPage = 'how';
        return <Intro {...data}/>
      case 'how':
        data.nextPage = 'legend';
        return <How {...data}/>
      case 'legend':
        data.nextPage = 'firstDest';
        return <Legend {...data}/>
      case 'firstDest':
        data.nextPage = 'place';
        return <FirstDest {...data}/>
      case 'place':
        data.nextPage = 'task';
        return <Place {...data}/>
      case 'task':
        data.nextPage = 'taskSuccess';
        return <Task {...data} />
      case 'cipher':
        data.nextPage = 'task';
        return <Cipher {...data} />
      case 'hint':
        data.nextPage = 'task';
        return <Hint {...data} />
      case 'taskSuccess':
        data.nextPage = 'place';
        return <TaskSuccess {...data} />
      case 'taskWrong':
        data.nextPage = 'task';
        return <TaskWrong {...data} />
      case 'finish':
        data.nextPage = 'results';
        return <Finish {...data} />
      case 'results':
        data.nextPage = 'feedback';
        return <Results {...data} />
      case 'feedback':
        data.nextPage = 'thanks';
        return <Feedback {...data} />
      case 'thanks':
        data.nextPage = 'task';
        return <Thanks {...data} />
      default:
        data.nextPage = 'intro';
        return <Language {...data}/>
    }
  }

  render() {
    return (
      <div>
        <div className="header"></div>
          {this.renderPage()} 
        <div className="footer"></div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.language,
    page: state.page,
    numberTask: state.numberTask,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    goToPage: (page) => dispatch({ type: ActionTypes.GO_TO_PAGE, page }),
    restart: () => dispatch({ type: ActionTypes.RESTART}),
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (Switcher);
