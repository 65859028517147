const guide = {
    en: [
            {
                title: 'Main Town Hall (Ratusz Glownego Miasta)',
                desc: 'The first town hall was built on this place in 14th century. After each reconstruction it gets bigger until in 16th century it got its current view with the cupola and gold covered statue of the Polish king, Sigismund II August on the top. On the entrance to Main Town Hall there is a sign of two lions, who look to the left. According to the legend, once, when Gdansk was under threat of attack, a local stonemason made these lions looking to the side from where Polish king was supposed to come to rescue the city.'
            },
            {
                title: 'Artus Court',
                desc: 'It was built in 14th century and got its name from the legend about King Arthur. It belonged to St. George Brotherhood which had knight-religion nature and organized tournaments according to Round Table customs. Only noble people could become the members of the Brotherhood. In 1617 Abraham van den Blocke rebuilt it decorating with the statues of ancient heroes and Fortuna on the top. Nobel and reach people, who were sentenced to death were “ceremoniously” beheaded here - in front of the Artus’ Court. Over the time, Artus Court started to accept merchants and soon became the official merchant house and later was transformed into Gdansk stock exchange. '
            },
            {
                title: 'Neptune Fountain',
                desc: "The Neptune Fountain was built in 1633 and became one of main symbols of Gdansk. The bronze statue of Roman god has his head bow down to the houses, where Polish kings usually stay in Gdansk. There is a legend, once Neptune got angry that people throwing golden coins into the fountain. He strikes the water with his trident so hard that the gold fell to pieces and formed tiny golden flakes which got into herbal liqueur. That's how Gdansk’s Goldwasser was created. When the WWII started people hid the bronze statue in a secret place, so after war finished it was back on its usual place."
            },
            {
                title: 'Fahrenheit monument',
                desc: 'Daniel Fahrenheit  was born in 1686 here in Gdansk in the merchant family. He orphaned and moved to Amsterdam to work as a merchant, when he was 16. However Daniel’s true passion lied in science and soon he was conducting various experiments and corresponding with the brightest scientists of the time. By 1724, after series of experiments he developed well-known temperature scale. To honor his work, people of Gdansk set up the monument with antique thermometer on the Long Market.'
            },
            {
                title: 'Green Gate and Green Bridge',
                desc: 'The renaissance Green Gate was designed in 16th century by Regnier from Amsterdam as a residence for Polish monarchs, but no Polish king ever lived in the building. Working on the Green Gate, Regnier got inspiration from Antwerp City Hall. The building materials – small Dutch bricks were specially brought from Amsterdam to get the desired look. The name of the gate is associated with the Green Bridge, which in the past was built of green colored stones. The Green Bridge used to be raised in the past to stop the unwelcomed visitors from getting into the Old Town.'
            },
            {
                title: 'Great Crane (Zuraw)',
                desc: 'It was built in 14th century and in one time it was the biggest working crane in the world. Zuraw was used to lift goods from ships (e.g. wine and beer barrels). Apart from loading function it served as a defense construction and as one of the gates of the city. The crane worked by two huge wooden wheels which were powered by four men walking inside. In such way they were able to lift up to 2 tons to a height of 11 metres. Now Crane is a part of the Maritime Museum. There is an exhibition showing the port life between the 16th and 18th centuries. '
            },
            {
                title: 'Mariacka street',
                desc: "It’s the most beautiful street in Gdansk and it's also very romantic. In summer evenings street musicians are playing for people who walk here. Sometimes it's also called Amber street because of many amber jewelry shops located there. The street is an example of historic Gdansk urban planning with terraces and richly decorated facades of houses which once belonged to wealthy merchants and goldsmiths. The beauty and charm of the place has always inspired writers and painters"
            },
            {
                title: 'Three Boar Heads of Gdansk',
                desc: 'In 1460 the city was under siege by the Teutonic Knights. After a while there was no food in the city and the last three pigs left. Then the head of the house of Ferber, Johann, came up with a brilliant idea. He ordered to load the heads of those pigs, full of valuable meat, into a catapult and launched at the attackers. And the trick worked as Teutonic Knights, decided that there are so much food in the city that they could literally throw it away. So they just packed up and left, ending the siege. Later, when his son, Eberhard was knighted by the Polish King for heroic actions in the Holy Land, he chose to decorate his family crest with three boar heads as a respect to his father wit and bravery.'
            },
            {
                title: "St. Mary's Basilica",
                desc: "St. Mary’s Basilica considered to be the largest brick church in the world. Its construction started in 1343 and last almost 160 years. Inside the church there is enough space for 25 thousand people, the entire population of the city in the time it was built. There stands amazing enormous astronomical clock created in 15th century. It shows the time and date, phases of the moon, the position of the moon and sun in relation to the signs of the zodiac, and even the calendar of saints. You may go to the top of main tower by 400 stairs to get amazing view on the city."
            },
            {
                title: 'Schopenhauer house',
                desc: " Świętego Ducha (Sancti Spiritus) 45/47  is the building where the famous philosopher Arthur Schopenhauer was born. Actually on the same street was born his mother. Move in the direction towards the river and find the house with the turtle on the top. The number of the house (the first one) is the answer."
            },
            {
                title: 'Great Armory (Arsenal)',
                desc: 'The Great Armory was built in the beginning of 17th century on the line of the city walls and worked as a city arsenal till the 19th century. The most impressive work of Flemish architect Antoni van Obbergen, it is built with small, red brick with sandstone decorations and gold that gives the impression as if the Arsenal consists of four separate houses. The well in front of the building never had water inside, instead it was used as an elevator to lift gunpowder and cannon balls from the storage in the basement. There is Roman goddess Minerva stands on the upper niche and five figures of Gdansk guards are on the top of the building.'
            },
            {
                title: 'Upland Gate',
                desc: 'The Upland Gate was built in 16th century in a Western line of fortifications, most vulnerable to attack. In front of the gate there was a channel with a bridge which was lifted for the night and in the periods of danger. Here, in front of the Upland Gate, were held ceremonies to greet Polish kings who visited the city. Flemish artist, Willem van den Block, decorated it with sandstone slabs and ornamenting it with three coats of arms: Prussia (unicorns), Poland (angels) and Gdansk (lions). Latin inscriptions, remind the authorities and the citizens of the key values for the state and society. One of those sayings reads: “The desires most valued by society are: peace, liberty and harmony.”'
            },
            {
                title: 'Prison Tower and Torture Chamber',
                desc: 'In 14th century, a gate and defending walls were built here. It was constantly attacked by the Knights of the Cross and after each attack it gets bigger and higher. The defense system was strong and complex with ramps and bastions. In 16th century, when the new line of defense was built, the medieval fortifications lost their significance. The gate was transformed into one of the largest prisons in Europe with no known successful escape attempts. The two gates towers linked to it were turned into a torture chamber which became a scene of cruel coercions of confession. Here was also the headquarter of the hangman who carried most of executions next to it, on the Coal Market. Now Amber Museum and Torture Museum are located here.'
            },
            {
                title: 'Golden Gate',
                desc: 'The renaissance Golden Gate built during 1612-1614 by Abraham van den Blocke. On each side of the gate, on attics, I put figures symbolizing the qualities of an ideal citizen. From the West side they represent: Peace, Freedom, Wealth and Fame. From the East (Long Lane) side they are Agreement, Justice, Piety and Prudence. The writing on the gate means "In agreement small republics grow, because of disagreement great republics fall".'
            },
        ],
}

export default guide;