import React from "react";
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { StyledExpansionPanel, StyledExpandMoreIcon } from '../styledExpansionPanel';
import guide from './guide';
import './style.css';

function Place({ goToPage, nextPage, numberTask, language }) {
  const withoutImg = [6,9];

  const regex = /\s/g;
  let result, indices = [];
  while ((result = regex.exec(guide[language][numberTask].desc)) && indices.length !== 7) {
    indices.push(result.index);
  }
  
  return (
    <div className="block-container">
      <div className="title">{guide[language][numberTask].title}</div>
      <div className="image">
        {
          (numberTask <= 13 && !withoutImg.includes(numberTask)) ? <img src={require(`./places/Zgrada${numberTask + 1}.png`)} alt={guide[language][numberTask].title} /> : null
        }
      </div>
      <div className="block-desc">
        <div className="text centered">Story
        </div><br />
        <div className="text-s">{guide[language][numberTask].desc.substring(0, indices[6])} ...</div>
        <StyledExpansionPanel className="read-more" style={{ minWidth: '250px', maxWidth: '450px' }}>
          <ExpansionPanelSummary
            expandIcon={<StyledExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{maxWidth: '150px', margin: 'auto'}}
          >
            <Typography>Read more</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              ... {guide[language][numberTask].desc.substring(indices[6])}
            </Typography>
          </ExpansionPanelDetails>
        </StyledExpansionPanel>
        <Button variant="outlined" onClick={() => goToPage(nextPage)}>
          Go to the task {numberTask + 1}
      </Button>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    ...state
  }
}

export default connect(mapStateToProps)(Place);