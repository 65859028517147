import React from "react";
import Button from '@material-ui/core/Button';

const guide = {
  en: "Dear kid, <br />" +
      "<br/>For centuries our family was guarding the ancient amulet which was the symbol of Gdansk Brotherhood."+
      "The secret of its location was given to the oldest child in the family, and now it's your time to take the guard."+
      "But first you would need to prove that you are worth such honour."+
      "You would need to find the location of ancient amulet solving the tasks I've prepared for you. <br/>"+
      "<br/>Your Grandpa"
}

function Legend({ goToPage, nextPage, lang }) {
  return (
    <div className="block-container">
      <div className="title">Legend</div><br />
      <div className="text">The legend of the quest:<br />
        <p dangerouslySetInnerHTML={{ __html: guide[lang]}}></p>
      </div>
      <Button variant="outlined" onClick={() => goToPage(nextPage)}>
        Next
      </Button>
    </div>
  );
}

export default Legend;